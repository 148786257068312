import React from 'react'
import axios from 'axios'

class ContactForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      name:       '',
      email:      '',
      message:    '',
      msgClass:   'success hidden',
      successMsg: ''
    }
    this.handleHtmlControlChange = this.handleHtmlControlChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleHtmlControlChange = (event) => {
    this.setState({[event.target.name]:event.target.value});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let formVal = {'name':this.state.name,'email':this.state.email, 'message':this.state.message};
    axios.post('https://getform.io/f/54d53088-c6f6-4f99-95cb-a886c6d8ae14',formVal).then( response => {
      this.setState({msgClass:'success'});
      this.setState({successMsg:'Vielen Dank für Deine Nachricht! Wir melden uns so schnell wie möglich zurüch.'});
      this.setState({name:''});
      this.setState({email:''});
      this.setState({message:''});
    }).catch( error => {
      this.setState({msgClass:'success error'});
      this.setState({successMsg:'Upps, da ist was schief gelaufen! Probiere es später nochmal.'});
    })
  }

  render() {
    const { name, email, message, msgClass, successMsg } = this.state;

    return (
      <div id="contact-form" className="contact-form">
        <form id="ajaxForm" onSubmit={this.handleSubmit} acceptCharset="UTF-8" >
          <label>Name
            <input id="cf-name" type="text" name="name" required value={name} onChange={this.handleHtmlControlChange} />
          </label>
          <label>Email
            <input id="cf-email" type="email" name="email" required value={email} onChange={this.handleHtmlControlChange} />
          </label>
          <label>Message
            <textarea name="message" rows="5" value={message} onChange={this.handleHtmlControlChange} />
          </label>
          <footer className="major">
            <div className={msgClass}>{successMsg}</div>
            <button className="button" type="submit">Senden</button>
          </footer>
        </form>
      </div>  
    )
  }
}

export default ContactForm